































































































































































import {
  Component, Vue
} from 'vue-property-decorator';
import { BFormInput } from 'bootstrap-vue';
import api from '@/services/api';
import SubscriptionEntry from '@/model/refill/SubscriptionEntry';
import SubscriptionLogEntry from '@/model/refill/SubscriptionLogEntry';

@Component({
  components: {

  }
})
export default class PaymentCardRecurringFill extends Vue {
  public amount = '';

  private charCode: any;

  private e: any;

  minAmount = 100;

  maxAmount = 7500;

  get isAmountValid() {
    const amount = (this.amount && parseInt(this.amount, 10)) || 0;
    return amount >= this.minAmount && amount <= this.maxAmount;
  }

  loaded = false;

  activeSubscription: SubscriptionEntry|null = null;

  subscriptionLogs: SubscriptionLogEntry[] = [];

  private dayOfMonth = 15;

  private days = [...Array(31).keys()].map((n) => (
    {
      value: n + 1,
      text: n < 30
        ? this.$t('DayNOfMonth', { day: n + 1 })
        : this.$t('LastDayOfMonth')
    }))

  get currentCard() {
    return this.$cards.currentCard!;
  }

  isWorking = false;

  public deactivateSubscription() {
    this.isWorking = true;

    api.recurringRefills
      .deactivate(this.currentCard.mifareId, (this.activeSubscription as SubscriptionEntry).subscriptionId as string)
      .then(() => { this.goBack(); })
      .catch(() => { this.isWorking = false; });
  }

  public initializeSubscription() {
    this.isWorking = true;
    api.verifone.initializeSubscription(this.currentCard.mifareId, parseFloat(this.amount), this.dayOfMonth).then((paymentForm) => {
      const form = this.$refs.verifoneForm as HTMLFormElement;
      form.action = paymentForm.verifonePaymentUrl;

      Object.entries(paymentForm.form).forEach(([key, value]) => {
        const input = document.createElement('input');
        input.setAttribute('type', 'hidden');
        input.setAttribute('name', key);
        input.setAttribute('value', value as string);
        form.appendChild(input);
      });

      form.submit();
    }).catch(() => { this.isWorking = false; });
  }

  public mounted() {
    const input = (this.$refs.numberInput as BFormInput);
    if (input) {
      input.focus();
      input.select();
    }

    api.recurringRefills.subscriptions(this.currentCard.mifareId).then((subscriptions) => {
      if (subscriptions.length > 0) {
        [this.activeSubscription] = subscriptions;
      }
      this.loaded = true;
    });

    api.recurringRefills.logs(this.currentCard.mifareId).then((subscriptionLogs) => {
      this.subscriptionLogs = subscriptionLogs;
    });
  }

  goBack() {
    this.$router.go(-1);
  }
}
