/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import fetch from 'isomorphic-fetch';
import { interpolate, getParameters } from '@/shared';

export class Http {
  accessToken: string | null = null;

  public basePath: string | null = null;

  public setAccessToken(token: string | null) {
    this.accessToken = token;
  }

  public setBasePath(basePath: string | null) {
    this.basePath = basePath;
  }

  /**
   * API GET request
   *
   * @param  {String} path
   * @param  {Object} query (optional)
   * @param  {Object} options (optional)
   * @return {Promise}
   */
  public get<T>(path: string, query?: object, options?: object): Promise<T> {
    query = { r: new Date().getTime(), ...(query || {}) };
    return this.apifetch(Http.getPathAndQuery(path, query), { method: 'GET', ...(options || {}) });
  }

  /**
   * API POST request
   *
   * @param  {String} path
   * @param  {Object} content
   * @param  {Object} query (optional)
   * @param  {Object} options (optional)
   * @return {Promise}
   */
  public post<T>(path: string, content: any, query?: object, options?: object): Promise<T> {
    if (!options) {
      options = query;
    }
    return this.apifetch(Http.getPathAndQuery(path, query, content), {
      method: 'POST',
      body: JSON.stringify(content),
      ...(options || {}),
    });
  }

  /**
   * API DELETE request
   *
   * @param  {String} path
   * @param  {Object} query (optional)
   * @param  {Object} options (optional)
   * @return {Promise}
   */
  public ['delete']<T>(path: string, query?: object, options?: object): Promise<T> {
    return this.apifetch(Http.getPathAndQuery(path, query), {
      method: 'DELETE',
      ...(options || {}),
    });
  }

  /**
   * API PUT request
   *
   * @param  {String} path
   * @param  {Object} content
   * @param  {Object} query (optional)
   * @param  {Object} options (optional)
   * @return {Promise}
   */
  public put<T>(path: string, content: any, query?: object, options?: object): Promise<T> {
    if (!options) {
      options = query;
    }
    return this.apifetch(Http.getPathAndQuery(path, query, content), {
      method: 'PUT',
      body: JSON.stringify(content),
      ...(options || {}),
    });
  }

  public apifetch(path: string, options: any): Promise<any> {
    return Http.validateRequest(path, options)
      .then(() => {
        path = `${this.basePath}${path}`;
        options = options || {};
        options.headers = options.headers || {};
        options.headers.Accept = options.headers.Accept || 'application/json';
        options.headers['Content-Type'] = options.headers['Content-Type'] || 'application/json';
        options.headers.Authorization = `Bearer ${this.accessToken}`;
        options.headers['Accept-Language'] = options.headers['Accept-Language'] || localStorage.getItem('lang') || 'no';
        return fetch(path, options);
      })
      .then(Http.validateResponse)
      .then(Http.parseJson);
  }

  public static validateResponse(response: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (response.status >= 200 && response.status < 300) {
        resolve(response);
      } else if (response.status === 401) {
        reject(new Error('Unauthorized'));
      } else {
        const error: Error = new Error(response.statusText);
        // error.response = response;
        reject(error);
      }
    });
  }

  public static validateRequest(path: string, options?: any): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve();
      // if (Vue.$auth.isAuthenticated()) {
      //   resolve()
      // } else {
      //   // debugger
      //   vue.$auth.login()
      //   reject(new Error('Not logged in'))
      // }
    });
  }

  public static parseJson(response: any): any {
    return response.json();
  }

  /** Returns path interpolated with values from content and / or query */
  public static getPathAndQuery(path: string, query?: object, content?: object): any {
    if (!query && !content) {
      return path;
    }
    // find which paramters are used in path
    const pathParams: any = getParameters(path);
    const queryParams: any = { ...query };
    // tslint:disable-next-line:prefer-for-of
    for (let i = 0; i < pathParams.length; i += 1) {
      delete queryParams[pathParams[i]];
    }
    // interpolate path with paramters both from content and query
    path = interpolate(path, { ...(content || {}), ...(query || {}) });

    // add additional query parameters
    if (Object.keys(queryParams).length > 0) {
      path += '?';
      const keys = Object.keys(queryParams);
      for (let i = 0; i < keys.length; i += 1) {
        const key = keys[i];
        path += `${key}=${encodeURIComponent(queryParams[key] || '')}&`;
      }
      // remove last &
      path = path.substr(0, path.length - 1);
    }
    return path;
  }
}

const http: Http = new Http();
export default http;
