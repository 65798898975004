/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */

import Vue, { PluginObject } from 'vue';
import { LocaleMessageObject } from 'vue-i18n';
import Card from '@/model/card/Card';
import RefillTransactionInfo from '@/model/refill/RefillTransactionInfo';

import PaypalPaymentOptions from '@/model/paypal/PaypalPaymentOptions';
import SamvirkSavingRule from '@/model/samvirk/SamvirkSavingRule';
import VerifoneForm from '@/model/verifone/VerifoneForm';
import CheckoutSession from '@/model/verifone/CheckoutSession';
import SubscriptionEntry from '@/model/refill/SubscriptionEntry';
import SubscriptionLogEntry from '@/model/refill/SubscriptionLogEntry';
import http from './http';

export class Api implements PluginObject<any> {
  // localization
  public localization = {
    getLang(lang: string): Promise<LocaleMessageObject> {
      return http.get<LocaleMessageObject>(`localization/${lang}`);
    }
  };

  // cards
  public cards = {
    getAll(): Promise<Card[]> {
      const query = 'cards';
      return http.get<Card[]>(query);
    },
    block(mifareId: string): Promise<Card> {
      const path = `cards/${mifareId}/block`;
      return http.put<Card>(path, {});
    },
    transfer(sourceMifareId: string, targetMifareId: string, amount: number) {
      const path = 'cards/transfer-balance';
      return http.post<Card[]>(path, { sourceMifareId, targetMifareId, amount });
    }
  };

  public bookingcards = {
    canCreateBookingCard(): Promise<boolean> {
      const query = 'booking-cards/can-create';
      return http.get<boolean>(query);
    },
    createBookingcard(): Promise<Card> {
      const path = 'booking-cards';
      return http.post<Card>(path, {});
    }
  }

  // refills
  public refills = {
    getAll(): Promise<RefillTransactionInfo[]> {
      const path = 'refills';
      return http.get<RefillTransactionInfo[]>(path);
    },
    getAllForCard(mifareId: string): Promise<RefillTransactionInfo[]> {
      const path = `refills/${mifareId}`;
      return http.get<RefillTransactionInfo[]>(path);
    }
  };

  public user = {
    getPersonKey(): Promise<string> {
      const query = 'user/personkey';
      return http.get<string>(query);
    }
  };

  public paypal = {
    getOptions(mifareId: string): Promise<PaypalPaymentOptions> {
      const path = 'paypal/options';
      return http.get<PaypalPaymentOptions>(path, { mifareId });
    }
  }

  public samvirk = {
    getSavingRules(): Promise<SamvirkSavingRule> {
      const path = 'samvirk/savings-rule';
      return http.get<SamvirkSavingRule>(path);
    }
  }

  public verifone = {
    initializePaymentV2(mifareId: string, amount: number): Promise<CheckoutSession> {
      const path = 'verifone/v2/initialize-payment';
      return http.get<CheckoutSession>(path, { mifareId, amount });
    },
    initializePayment(mifareId: string, amount: number): Promise<VerifoneForm> {
      const path = 'verifone/initialize-payment';
      return http.get<VerifoneForm>(path, { mifareId, amount });
    },
    initializeSubscription(mifareId: string, amount: number, dayOfMonth: number): Promise<VerifoneForm> {
      const path = 'verifone/initialize-subscription';
      return http.get<VerifoneForm>(path, { mifareId, amount, dayOfMonth });
    }
  }

  public recurringRefills = {
    subscriptions(mifareId: string): Promise<SubscriptionEntry[]> {
      const path = `recurring-refills/${mifareId}`;
      return http.get<SubscriptionEntry[]>(path);
    },
    logs(mifareId: string): Promise<SubscriptionLogEntry[]> {
      const path = `recurring-refills/${mifareId}/logs`;
      return http.get<SubscriptionLogEntry[]>(path);
    },
    deactivate(mifareId: string, subscriptionId: string): Promise<object> {
      const path = `recurring-refills/${mifareId}/deactivate/${subscriptionId}`;
      return http.post<object>(path, {});
    }
  }

  // installer
  public install(vue: typeof Vue, options?: any): void {
    vue.prototype.$api = this;
  }
}

const api: Api = new Api();

export default api;
