/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Finds names of interpolation parameters in string
 *
 * @param  {String} string
 * @return {Array}
 */
export function getParameters(input: string): any {
  const parameters: any[] = [];
  const re = /{{([\s\S]+?)}}/g;
  let match: any;
  // tslint:disable-next-line:no-conditional-assignment
  match = re.exec(input);
  while (match !== null) {
    parameters.push(match[1]);
    match = re.exec(input);
  }
  return parameters;
}

/**
 * Compiles a string and interpolate data from the options argument.
 *
 * @param  {String} string
 * @param  {Object} options
 * @return {String}
 */
export function interpolate(input: string, options: any): any {
  const parameters = getParameters(input);
  let output = input;
  for (let i = 0; i < parameters.length; i += 1) {
    const param = parameters[i];
    const value = options[param] || '';
    output = output.replace(`{${param}}`, value);
  }
  return output;
}

export function camelCase(input: string): string {
  if (input && input.length > 0) {
    return input.charAt(0).toLowerCase() + input.slice(1);
  }
  return input;
}

export function capitalize(input: string): string {
  if (input && input.length > 0) {
    return input.charAt(0).toUpperCase() + input.slice(1);
  }
  return input;
}

export function isObject(val: any): boolean {
  if (val === null) {
    return false;
  }
  return typeof val === 'function' || typeof val === 'object';
}
