<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import AllIosIcon from 'vue-ionicons/dist/ionicons-ios';

// 3rd party filters
import moment from 'moment';
import 'moment/locale/en-gb';
import 'moment/locale/nb';

import router from '@/router';
import http from '@/services/http';
import i18n, { setLanguage } from '@/i18n';
import api from '@/services/api';
import store from '@/store';

Vue.prototype.$moment = moment;

const bus = new Vue();
Vue.prototype.$bus = bus;

// services
Vue.use(api);
Vue.use(store);
Vue.use(BootstrapVue);
Vue.use(AllIosIcon);
// Vue.mixin({router });

export default {
  name: 'app',
  props: {
    apiBasePath: {
      type: String,
      required: true,
    },
    accessToken: {
      type: String,
      required: true,
    },
    lang: {
      type: String,
      default: 'no'
    }
  }, // TODO: Add default lang
  i18n,
  router,
  store,
  data() {
    return {
      dataLoaded: false
    };
  },
  watch: {
    apiBasePath(val) {
      this.loadData();
    },
    accessToken(val) {
      this.loadData();
    },
    lang(val) {
      setLanguage(val);
    }
  },
  methods: {
    loadData() {
      if (this.accessToken && this.apiBasePath && !this.dataLoaded) {
        http.setBasePath(this.apiBasePath);
        http.setAccessToken(this.accessToken);
        this.$user.loadUser();
        this.$cards.loadAll();
        this.$cards.loadCanCreateBookingCard();
        this.dataLoaded = true;
      }
    }
  },
  mounted() {
    setLanguage(this.lang || 'en');

    this.loadData();

    // loaded events
    this.$bus.$on('hasLoaded', (evnt) => {
      this.$emit('hasLoaded', evnt);
    });

    // fillpages events
    this.$bus.$on('OpenFillPage', (evnt) => {
      this.$emit('OpenFillPage', evnt);
    });
    this.$bus.$on('CloseFillPage', (evnt) => {
      this.$emit('CloseFillPage', evnt);
    });

    // payments events
    this.$bus.$on('paymentCompleted', (evnt) => {
      this.$emit('paymentCompleted', evnt);
    });
    this.$bus.$on('paymentStarted', (evnt) => {
      this.$emit('paymentStarted', evnt);
    });
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    router.push('/').catch((err) => {});
  }
};
</script>

<style lang="scss">
  @import "@/style/variables.scss";

  @import '~bootstrap/scss/bootstrap';
  @import '~bootstrap-vue/src/index.scss';

  @import "@/style/style.scss";

</style>
