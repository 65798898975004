import { LocaleMessageObject } from 'vue-i18n';

export const de: LocaleMessageObject = {
  message: 'hallo',
  Home: 'Home',
  Planning: 'Planning 12345',
  card: 'Karte',
  armband: 'Armband',
  SamvirkCard: 'Digital Card',
  CreateDigitalCard: 'Create digital gift card',
  DigitalCardJumboTitle: 'Digital Gift Cards and Brunstad Card',
  DigitalCardJumbo: 'The Brunstad card is split in two; one digital and one physical. <br/><br /> The digital card can be used for bookings via my.oslofjord. Anyone over the age of 16 who does not have a digital card can create one themselves on this page. <br/><br /> The physical card can only be used in kiosks and restaurants at the Oslofjord Convention Centre. Orders for physical Brunstad cards are made at reception.',
  DigitalCards: 'Digital gift cards',
  DigitalCardsTermsText: 'Click here to read the terms and conditions for digital gift cards',
  DigitalCardsTermsPath: 'download/digital_cards_terms_de.pdf',
  DigitalGiftCardRefillDisabled: 'Das Aufladen der Digitalen Geschenkkarten ist vorübergehend deaktivert.',
  BrunstadCardsTermsText: 'Hier klicken um die geltenden Bedingungen für die Oslofjord Geschenkkarte anzusehen.',
  BrunstadCardsTermsPath: 'download/payment_cards_terms_de.pdf',
  RefillReceipts: 'Quittungen zeigen',
  RefillSamvirkCardTemporarilyDisabled: 'Das Aufladen einer digitalen Geschenkkarte ist leider derzeit nicht möglich. Ein eventuelles Guthaben auf dieser Karte ist weiterhin für Ihre Nutzung verfügbar.',
  RefillSamvirkCardTemporarilyDisabledExtended: 'Das Aufladen einer digitalen Geschenkkarte ist leider derzeit nicht möglich. Ein eventuelles Guthaben auf dieser Karte ist weiterhin für Ihre Nutzung verfügbar. Ein Guthaben auf Ihrer digitalen Geschenkkarte, das zur Begleichung Ihrer Hotelvereinbarung verwendet werden soll, wird bis spätestens 31. Juli 2024 übertragen.',
  Close: 'Schließen',
  Cancel: 'Abbrechen',
  ShowCards: 'Kartenübersicht',
  Refills: 'Aufladungen',
  Purchases: 'Käufe',
  Update: 'Aktualisieren',
  UpdateBalance: 'Saldo aktualisieren',
  Refill: 'Aufladen',
  MonthlyRefill: 'Monatliches Aufladen',
  Block: 'Sperren',
  Receipt: 'Quittung',
  ReceiptSubtitle: 'Kartenaufladung',
  PaymentReceiptSubtitle: 'Zahlung mit Karte',
  OrderRef: 'Auftragsnr.',
  OrgNumber: 'Org.nr.',
  PaymentMethod: 'Zahlungsmittel',
  Total: 'Summe',
  FillCard: 'Karte aufladen',
  Transfer: 'Umbuchen',
  Balance: 'Saldo',
  Amount: 'Betrag',
  NewBalance: 'Neuer Saldo',
  Blocked: 'Gesperrt',
  Ok: 'OK',
  BlockCardConfirmation: 'Möchtest Du die Karte wirklich sperren?',
  BlockCardTitle: 'Karte sperren',
  GoToPayment: 'Mit Debit-/Kreditkarte zahlen',
  CardTransferBalanceTitle: 'Zwischen Karten umbuchen',
  ToCard: 'Zielkarte',
  FromCard: 'Von Karte',
  CardsNotSelectedError: 'Du musst eine Zielkarte auswählen',
  TransferAmountBelowZeroError: 'Der Betrag muss höher als 0 sein',
  CardTransferBalanceGenericError: 'Das hat leider nicht geklappt.',
  PleaseEnterValidAmount: 'Bitten einen Betrag zwischen {minAmount} und {maxAmount} angeben.',
  MaxBalanceWarning: 'Der neue Saldo darf {maxBalance} nicht überschreiten.',
  PersonalCardReminder: 'Denke daran, dass deine Karte persönlich ist und nur von Dir selber aufgeladen werden sollte.',
  PaypalSuccessfulTitle: 'Paypal-Zahlung',
  PaypalSuccessfulMessage: 'Deine Zahlung ist erfolgreich durchgeführt. Es kann jedoch bis zu einer Minute dauern bis sie vollständig verarbeitet ist und im Saldo bzw. in der Kartenübersicht sichtbar wird.',
  SupportedCards: 'Die folgenden Karten sind unterstützt',
  PaypalAsAlternative: 'Solltest du mit keiner der unterstützen Karten zahlen können, kannst du hier klicken um mit Paypal zu zahlen.',
  CardNumber: 'Kartennummer',
  DayNOfMonth: 'Zum {day}. jeden Monats',
  InitializeSubscription: 'Mit Debit-/Kreditkarte aktivieren',
  LastDayOfMonth: 'Zum letzten Tag des Monats',
  NextExecution: 'Nächste Fälligkeit',
  PaymentDay: 'Zahlung',
  RecurringFillTitle: 'Automatische monatliche Aufladung',
  RemoveRecurringRefill: 'Monatliche Aufladung entfernen',
  SubscriptionHistory: 'Verlauf',
  SubscriptionActivated: 'Automatische Aufladung aktiviert',
  SubscriptionPaymentSuccessful: 'Automatische Aufladung erfolgreich',
  SubscriptionPaymentFailed: 'Automatische Aufladung fehlgeschlagen',
  SubscriptionDeactivated: 'Automatische Aufladung entfernt',
  SubscriptionChangeNotification: 'Falls du eine Änderung der automatischen Aufladung vornehmen möchtest, bitte die monatliche Aufladung entfernen und neu einrichten.'
};

export default de;
