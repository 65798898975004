







import MyPaymentCards from './MyPaymentCards.vue';

export default {
  name: 'Home',
  components: {
    MyPaymentCards
  }
};
