/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store } from 'vuex';
import api from '@/services/api';
import Card from '@/model/card/Card';
import TransferBalanceAction from '@/model/card/TransferBalanceAction';
import BaseStore from '../base/base';

export interface CardState {
  cards: Card[];
  currentCardId: string;
  canCreateBookingCard: boolean;
}

export class CardStore extends BaseStore<CardState> {
  constructor(rootStore: Store<any>) {
    super('cards', rootStore, {
      cards: [],
      currentCardId: '',
      canCreateBookingCard: false,
    });

    this.getters = {
      ...this.getters,
      getOrderedCards(state) {
        const { cards } = state;

        return cards.sort((a, b) => b.type - a.type);
      },
      getCurrentCard(state) {
        return state.cards.find((c) => c.mifareId === state.currentCardId);
      },
      canCreateBookingCard(state) {
        return state.canCreateBookingCard;
      }
    };

    this.mutations = {
      ...this.mutations,
      setAll: (state: CardState, items: Card[]) => {
        state.cards = items;
      },
      setCurrentCardId: (state: CardState, cardId: string) => {
        state.currentCardId = cardId;
      },
      updateBlockedStatus: (state: CardState, item: Card) => {
        for (let i = 0; i < state.cards.length; i += 1) {
          const curr = state.cards[i];
          if (curr.mifareId.toString() === item.mifareId.toString()) {
            curr.isBlocked = item.isBlocked;
            break;
          }
        }
      },
      updateCanCreateBookingCardStatus: (state: CardState, canCreate: boolean) => {
        state.canCreateBookingCard = canCreate;
      }
    };

    this.actions = {
      ...this.actions,
      loadAll: async () => {
        const items: Card[] = await api.cards.getAll();
        this.setAll(items);
        return items;
      },
      blockCard: async (state, mifareId: string) => {
        const card = await api.cards.block(mifareId);
        this.updateBlockedStatus(card);
        return card;
      },
      transferBalance: async (state, action: TransferBalanceAction) => {
        const updatedCards = await api.cards.transfer(action.sourceMifareId, action.targetMifareId, action.amount);
        this.loadAll();
        return updatedCards;
      },
      loadCanCreateBookingCard: async (state) => {
        const value = await api.bookingcards.canCreateBookingCard();
        this.updateCanCreateBookingCardStatus(value);
        return value;
      },
      createBookingCard: async (state) => {
        const card = await api.bookingcards.createBookingcard();
        this.loadAll();
        this.loadCanCreateBookingCard();
        return card;
      }
    };
  }

  // typed mutations
  public setAll = (items: Card[]) => this.commit('setAll', items);

  public updateBlockedStatus = (item: Card) => this.commit('updateBlockedStatus', item);

  public setCurrentCardId = (cardId: string) => this.commit('setCurrentCardId', cardId);

  public updateCanCreateBookingCardStatus = (canCreate: boolean) => this.commit('updateCanCreateBookingCardStatus', canCreate);

  // typed actions

  public loadAll = () => this.dispatch('loadAll') as Promise<Card[]>;

  public blockCard = (mifareId: string) => this.dispatch('blockCard', mifareId) as Promise<Card>;

  public transferBalance = (action: TransferBalanceAction) => this.dispatch('transferBalance', action) as Promise<Card>;

  public loadCanCreateBookingCard = () => this.dispatch('loadCanCreateBookingCard') as Promise<boolean>;

  public createBookingCard = () => this.dispatch('createBookingCard') as Promise<boolean>;

  public get getOrderedCards() { return this.read<Card[] | undefined>('getOrderedCards'); }

  public get currentCard() { return this.read<Card | undefined>('getCurrentCard'); }

  public get canCreateBookingCard() { return this.read<Card | undefined>('canCreateBookingCard'); }
}
