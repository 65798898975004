import Vue from 'vue';
import VueRouter, { RouteConfig, Route } from 'vue-router';
import Home from '@/views/Home.vue';
import PaymentCardFill from '@/components/PaymentCardFill.vue';
import PaymentCardRecurringFill from '@/components/PaymentCardRecurringFill.vue';
import PaymentCardTransfer from '@/components/PaymentCardTransfer.vue';
import PaymentCardDetails from '@/views/PaymentCardDetails.vue';
import { cards } from '@/store';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    name: 'Home',
    path: '/',
    component: Home
  },
  {
    name: 'PaymentCardDetails',
    path: '/details/:mifareId',
    component: PaymentCardDetails
  },
  {
    name: 'Fill',
    path: '/fill/:mifareId',
    component: PaymentCardFill,
    beforeEnter: (to: Route, from: Route, next) => {
      if (to.params.mifareId) {
        // this.$cards.set
        cards.setCurrentCardId(to.params.mifareId);
      }
      next();
    }
  },
  {
    name: 'RecurringFill',
    path: '/recurring-fill/:mifareId',
    component: PaymentCardRecurringFill,
    beforeEnter: (to: Route, from: Route, next) => {
      if (to.params.mifareId) {
        // this.$cards.set
        cards.setCurrentCardId(to.params.mifareId);
      }
      next();
    }
  },
  {
    name: 'Transfer',
    path: '/transfer/:mifareId',
    component: PaymentCardTransfer,
    beforeEnter: (to: Route, from: Route, next) => {
      if (to.params.mifareId) {
        // this.$cards.set
        cards.setCurrentCardId(to.params.mifareId);
      }
      next();
    }
  }
];

const router = new VueRouter({
  mode: 'abstract',
  routes
});

export default router;
