/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import Vue, { PluginObject } from 'vue';
import Vuex from 'vuex';
import { CardStore } from '@/store/modules/CardStore';
import { UserStore } from '@/store/modules/UserStore';
import { RefillTransactionStore } from './modules/RefillTransactionStore';

Vue.use(Vuex);

export class RootStore extends Vuex.Store<any> implements PluginObject<any> {
  public cards: CardStore = new CardStore(this);

  public user: UserStore = new UserStore(this);

  public refills: RefillTransactionStore = new RefillTransactionStore(this);

  constructor() {
    super({ strict: true });
  }

  public install(vue: typeof Vue, options?: any): any {
    // register state globally
    vue.prototype.$state = this.state;

    this.registerModule(this.cards.name, this.cards);
    this.registerModule(this.user.name, this.user);
    this.registerModule(this.refills.name, this.refills);

    Vue.prototype[`$${this.cards.name}`] = this.cards;
    Vue.prototype[`$${this.user.name}`] = this.user;
    Vue.prototype[`$${this.refills.name}`] = this.refills;

    // vue.use(cards);
    // global actions here
  }
}

const store: RootStore = new RootStore();
Vue.use(store);

export const { cards, user, refills } = store;

// eport Store
export default store;
