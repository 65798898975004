import { LocaleMessageObject } from 'vue-i18n';

export const en: LocaleMessageObject = {
  message: 'hello',
  Home: 'Home',
  Planning: 'Planning 12345',
  card: 'Card',
  armband: 'Armband',
  SamvirkCard: 'Digital Card',
  CreateDigitalCard: 'Create digital gift card',
  DigitalCardJumboTitle: 'Digital Gift Cards and Brunstad Card',
  DigitalCardJumbo: 'The Brunstad card is split in two; one digital and one physical. <br/><br /> The digital card can be used for bookings via my.oslofjord. Anyone over the age of 16 who does not have a digital card can create one themselves on this page. <br/><br /> The physical card can only be used in kiosks and restaurants at the Oslofjord Convention Centre. Orders for physical Brunstad cards are made at reception.',
  DigitalCards: 'Digital gift cards',
  DigitalCardsTermsText: 'Click here to read the terms and conditions for digital gift cards',
  DigitalCardsTermsPath: 'download/digital_cards_terms_en.pdf',
  DigitalGiftCardRefillDisabled: 'Refill of digital gift cards is temporarily disabled.',
  BrunstadCardsTermsText: 'Click here to read the terms and conditions for Oslofjord\'s gift cards.',
  BrunstadCardsTermsPath: 'download/payment_cards_terms_en.pdf',
  BrunstadCards: 'Brunstad cards',
  RefillReceipts: 'Show receipts',
  RefillBrunstadCardTemporarilyDisabled: 'Due to the replacement of the new cash system at Oslofjord, refilling of brunstad cards is unfortunately not available at the moment. <br /><br />Work is being done on a new solution for online refilling. This solution will be launched during 2024.',
  RefillSamvirkCardTemporarilyDisabled: 'Refilling a digital gift card is unfortunately unavailable at this time; however, any reamining balance on this card is still available for your use.',
  RefillSamvirkCardTemporarilyDisabledExtended: 'Refilling a digital gift card is unfortunately unavailable at this time; however, any reamining balance on this card is still available for your use. Any balance on your digital gift card which is to be transferred to pay off your hotel agreement will be transferred on or before 31 July 2024.',
  Close: 'Close',
  Cancel: 'Cancel',
  ShowCards: 'Card overview',
  Refills: 'Refills',
  RecurringFillTitle: 'Automatic monthly card refill',
  MonthlyRefill: 'Monthly refill',
  RemoveRecurringRefill: 'Stop monthly refill',
  CardNumber: 'Card number',
  NextExecution: 'Next execution',
  InitializeSubscription: 'Set up with debit/credit card',
  PaymentDay: 'Payment',
  DayNOfMonth: '{day}. every month',
  LastDayOfMonth: 'Last day of month',
  SubscriptionHistory: 'History',
  Purchases: 'Purchases',
  Update: 'Refresh',
  UpdateBalance: 'Update balance',
  Refill: 'Refill',
  Block: 'Block',
  Receipt: 'Receipt',
  ReceiptSubtitle: 'Payment Card Refill',
  PaymentReceiptSubtitle: 'Payment using payment card',
  OrderRef: 'Order-id',
  OrgNumber: 'Org. no',
  PaymentMethod: 'Payment',
  Total: 'Total',
  FillCard: 'Refill card',
  Transfer: 'Transfer',
  Balance: 'Balance',
  Amount: 'Amount',
  NewBalance: 'New balance',
  Blocked: 'Blocked',
  Ok: 'OK',
  BlockCardConfirmation: 'Do you really want to block this payment card?',
  BlockCardTitle: 'Block card',
  GoToPayment: 'Pay with debit/credit card',
  CardTransferBalanceTitle: 'Transfer from card to card',
  ToCard: 'To card',
  FromCard: 'From card',
  CardsNotSelectedError: 'You must select a target card',
  TransferAmountBelowZeroError: 'You must specify amount greater than 0',
  CardTransferBalanceGenericError: 'Something went wrong',
  PleaseEnterValidAmount: 'Please enter an amount between {minAmount} and {maxAmount}.',
  MaxBalanceWarning: 'The new balance cannot exceed {maxBalance}.',
  PersonalCardReminder: 'Remember that your card is personal and should only be refilled by yourself.',
  PaypalSuccessfulTitle: 'Paypal payment',
  PaypalSuccessfulMessage: 'Your payment was successful. Please note that it may take up to a minute until the payment is processed and your balance is updated.',
  SupportedCards: 'The following cards are supported',
  PaypalAsAlternative: 'If you cannot pay with any of the supported cards, click here to pay using Paypal.',
  SubscriptionActivated: 'Monthly refill activated',
  SubscriptionPaymentSuccessful: 'Automatic refill successful',
  SubscriptionPaymentFailed: 'Automatic refill failed',
  SubscriptionDeactivated: 'Monthly refill stopped',
  SubscriptionChangeNotification: 'In case you want to change amount, payment card or payment date, please stop the current automatic refill and set up over again.',
  RefillWarning: 'NB! You are about to make a payment into your Brunstad card. This money cannot be transferred to your Samvirk account if it is paid into your Brunstad card in error. Right of withdrawal does not apply to payments made into an already existing Brunstad card.',
  LastUpdated2023: 'Last updated 31.12.2023'

};

export default en;
