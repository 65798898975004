import VueI18n from 'vue-i18n';
import Vue from 'vue';
import moment from 'moment';
import api from '@/services/api';
import enStrings from './en';
import nbStrings from './nb';
import deStrings from './de';

Vue.use(VueI18n);

const i18n: VueI18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en'
});

const loadedLanguages: string[] = []; // no languages loaded default

export function setLanguage(lang: string): void {
  let strings = enStrings;
  if (lang === 'nb' || lang === 'no') {
    strings = nbStrings;
    lang = 'no';
  }
  if (lang === 'de') {
    strings = deStrings;
  }
  i18n.setLocaleMessage(lang, strings);
  i18n.locale = lang;
  moment.locale(lang);
}

// export async function ensureLanguageAsync(): Promise<void> {
//   const lang: string = localStorage.getItem('lang') || 'no';

//   if (i18n.locale !== lang) {
//     if (!loadedLanguages.includes(lang)) {
//       const locale: VueI18n.LocaleMessageObject = await api.localization.getLang(lang);
//       i18n.setLocaleMessage(lang, locale);
//       loadedLanguages.push(lang);
//     }
//     i18n.locale = lang;
//   }
// }

// export async function setI18nLanguageAsync(lang: string): Promise<void> {
//   let l = lang;
//   localStorage.setItem('lang', l);
//   if (l === 'no') {
//     l = 'nb';
//   }
//   moment.locale(l);
//   // set local storage lang
//   await ensureLanguageAsync();
// }

export default i18n;
