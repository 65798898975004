/* eslint-disable @typescript-eslint/no-explicit-any */
import { Store } from 'vuex';
import api from '@/services/api';
import BaseStore from '../base/base';

export interface UserState {
  personKey: string;
}

export class UserStore extends BaseStore<UserState> {
  constructor(rootStore: Store<any>) {
    super('user', rootStore, {
      personKey: ''
    });

    this.getters = {
      ...this.getters,
      personKey(state) {
        return state.personKey;
      }
    };

    this.mutations = {
      ...this.mutations,
      setPersonKey: (state: UserState, value: string) => {
        state.personKey = value;
      }
    };

    this.actions = {
      ...this.actions,
      loadUser: async () => {
        const personKey: string = await api.user.getPersonKey();
        this.setPersonKey(personKey);
        return personKey;
      }
    };
  }

  // typed mutations
  public setPersonKey = (value: string) => this.commit('setPersonKey', value);

  // typed actions
  public loadUser = () => this.dispatch('loadUser') as Promise<string>;
}
