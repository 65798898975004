

















































































/* eslint-disable class-methods-use-this */
import { Component, Vue } from 'vue-property-decorator';
import RefillTransactionInfo from '@/model/refill/RefillTransactionInfo';

@Component({
  components: {
  }
})
export default class RefillTransactionsList extends Vue {
  constructor() {
    super();
    this.$bus.$emit('hasLoaded');
  }

  viewReceipt(k: RefillTransactionInfo) {
    this.$refills.setCurrentTransactionNumber(k.transactionNumber);
  }

  format(number: number) {
    return number.toLocaleString(undefined, { minimumFractionDigits: number % 1 !== 0 ? 2 : 0 });
  }

  isCateringRefill(refill: RefillTransactionInfo) {
    return !refill.mifareId.toString().startsWith('999') && new Date(refill.timestamp) > new Date('2024-07-01');
  }

  isSamvirkRefill(refill: RefillTransactionInfo) {
    return refill.mifareId.toString().startsWith('999');
  }
}
