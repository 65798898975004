








































































































































/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-restricted-globals */

import { Component, Vue } from 'vue-property-decorator';
import RefillTransactionsList from '@/components/RefillTransactionsList.vue';
import Card from '@/model/card/Card';

@Component({
  components: {
    RefillTransactionsList
  }
})
export default class PaymentCardDetails extends Vue {
  public amount = 0;

  goBack() {
    this.$bus.$emit('CloseFillPage');
    this.$router.go(-1);
  }

  get currentCard() {
    return this.$cards.currentCard!;
  }

  format(number: number) {
    return number.toLocaleString(undefined, { minimumFractionDigits: number % 1 !== 0 ? 2 : 0 });
  }

  refreshCard() {
    this.$refills.loadForCard(parseInt(this.currentCard.mifareId, 10));
    this.$cards.loadAll();
  }

  async blockCard(k: Card) {
    if (!k.isBlocked) {
      const result = confirm(this.$t('BlockCardConfirmation').toString());
      // const result = await this.$bvModal.msgBoxConfirm(this.$t('BlockCardConfirmation').toString(), {
      //   title: this.$t('BlockCardTitle').toString(),
      //   size: 'lg',
      //   buttonSize: 'lg',
      //   okVariant: 'danger',
      //   cancelVariant: 'dark',
      //   okTitle: this.$t('Ok').toString(),
      //   cancelTitle: this.$t('Cancel').toString(),
      //   centered: true
      // });
      if (result) {
        await this.$cards.blockCard(k.mifareId);
      }
    }
  }

  disableFillBrunstadCard(card: Card | undefined) {
    if (card === undefined) return true;
    return card.type === 0 && !card.canRefill;
  }

  disableFillSamvirkCard(card: Card | undefined) {
    if (card === undefined) return true;
    return card.type === 1 && !card.canRefill;
  }

  openFill(k: Card) {
    this.$bus.$emit('OpenFillPage');
    this.$router.push({
      name: 'Fill',
      params: { mifareId: k.mifareId },
    }).catch(() => {
      // Zzz
    });
  }

  openRecurringFill(k: Card) {
    this.$bus.$emit('OpenRecurringFillPage');
    this.$router.push({
      name: 'RecurringFill',
      params: { mifareId: k.mifareId },
    }).catch(() => {
      // Zzz
    });
  }

  openTransfer(k: Card) {
    this.$bus.$emit('OpenTransferPage');
    this.$router.push({
      name: 'Transfer',
      params: { mifareId: k.mifareId },
    }).catch(() => {
      // Zzz
    });
  }
}
