





















































































































































































































































/* eslint-disable class-methods-use-this */
import {
  Prop, Component, Vue
} from 'vue-property-decorator';
import Card from '@/model/card/Card';

@Component({
  components: {
  }
})
export default class PaymentCardsList extends Vue {
  constructor() {
    super();
    this.$bus.$emit('hasLoaded');
  }

  openDetails(k: Card) {
    this.$bus.$emit('OpenFillPage');
    this.$cards.setCurrentCardId(k.mifareId);
    this.$refills.loadForCard(parseInt(k.mifareId, 10));
    this.$router.push({
      name: 'PaymentCardDetails',
      params: { mifareId: k.mifareId },
    }).catch((err: Error) => {
      // Zzz
    });
  }

  get orderedCards() {
    return this.$cards.getOrderedCards!;
  }

  get retailcards() {
    return this.$cards.getOrderedCards!.filter((f) => f.type === 0);
  }

  get bookingcards() {
    return this.$cards.getOrderedCards!.filter((f) => f.type === 1);
  }

  createBookingCard() {
    // eslint-disable-next-line no-restricted-globals
    if (confirm('Sikker på du vil opprette et digitalt gavekort?')) {
      this.$cards.createBookingCard();
    }
  }

  showSamvirkInfo() {
    return true;
    // return new Date() > new Date('2022-12-15 16:00:00');
  }

  refreshCard() {
    this.$cards.loadAll();
  }

  format(number: number) {
    return number.toLocaleString(undefined, { minimumFractionDigits: number % 1 !== 0 ? 2 : 0 });
  }
}
