/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable max-len */
import { Store } from 'vuex';

import api from '@/services/api';
import RefillTransactionInfo from '@/model/refill/RefillTransactionInfo';
import BaseStore from '../base/base';

export interface RefillTransactionState {
  transactions: RefillTransactionInfo[];
  currentTransactionNumber: string;
  currentCardId: number;
}

export class RefillTransactionStore extends BaseStore<RefillTransactionState> {
  constructor(rootStore: Store<any>) {
    super('refills', rootStore, {
      transactions: [],
      currentTransactionNumber: '',
      currentCardId: 0
    });

    this.getters = {
      ...this.getters,
      getCurrentTransaction(state) {
        return state.transactions.find((c) => c.transactionNumber === state.currentTransactionNumber);
      },
      getCurrentCardTransactions(state) {
        return state.transactions.filter((c) => c.mifareId.toString() === state.currentCardId.toString()).sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1));
      }
    };

    this.mutations = {
      ...this.mutations,
      setAll: (state: RefillTransactionState, items: RefillTransactionInfo[]) => {
        state.transactions = items;
      },
      addOrUpdate: (state: RefillTransactionState, items: RefillTransactionInfo[]) => {
        const existingItems = state.transactions || [];
        const result = items || [];
        for (let i = 0; i < existingItems.length; i += 1) {
          const existingItem = existingItems[i];
          if (!result.find((itm) => itm.transactionGuid === existingItem.transactionGuid)) {
            result.push(existingItem);
          }
        }
        state.transactions = result;
      },
      setCurrentTransactionNumber: (state: RefillTransactionState, transactionNumber: string) => {
        state.currentTransactionNumber = transactionNumber;
      },
      setCurrentCardId: (state: RefillTransactionState, mifareId: number) => {
        state.currentCardId = mifareId;
      }
    };

    this.actions = {
      ...this.actions,
      loadAll: async () => {
        const items: RefillTransactionInfo[] = await api.refills.getAll();
        this.setAll(items);
        return items;
      },
      loadForCard: async (state, mifareId) => {
        this.setCurrentCardId(mifareId);
        const items: RefillTransactionInfo[] = await api.refills.getAllForCard(mifareId);
        this.addOrUpdate(items);
        return items;
      },
    };
  }

  // typed mutations
  public setAll = (items: RefillTransactionInfo[]) => this.commit('setAll', items);

  public addOrUpdate = (items: RefillTransactionInfo[]) => this.commit('addOrUpdate', items);

  public setCurrentTransactionNumber = (transactionNumber: string) => this.commit('setCurrentTransactionNumber', transactionNumber);

  public setCurrentCardId = (mifareId: number) => this.commit('setCurrentCardId', mifareId);

  // typed actions
  public loadAll = () => this.dispatch('loadAll') as Promise<RefillTransactionInfo[]>;

  public loadForCard = (mifareId: number) => this.dispatch('loadForCard', mifareId) as Promise<RefillTransactionInfo[]>;

  public get currentTransaction() { return this.read<RefillTransactionInfo | undefined>('getCurrentTransaction'); }

  public get currentCardTransactions() { return this.read<RefillTransactionInfo | undefined>('getCurrentCardTransactions'); }
}
