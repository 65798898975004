import { LocaleMessageObject } from 'vue-i18n';

const nb: LocaleMessageObject = {
  message: 'hei!',
  Home: 'Startside',
  Planning: 'Planlegg',
  card: 'Kort',
  armband: 'Armbånd',
  SamvirkCard: 'Digitalt kort',
  CreateDigitalCard: 'Opprett digitalt gavekort',
  DigitalCardJumboTitle: 'Digitalt gavekort og Brunstadkort',
  DigitalCardJumbo: 'Brunstadkortet er splittet i to; et digitalt og et fysisk.<br /><br /> Det digitale kortet kan brukes på booking via my.oslofjord. Alle over 16 år som ikke har et digitalt kort, får mulighet til å opprette selv på denne siden.<br /><br /> Det fysiske kortet kan bare brukes i kiosker og restauranter på Oslofjord Convention Center. Bestilling av fysiske Brunstadkort gjøres i resepsjonen.',
  DigitalCards: 'Digitale gavekort',
  DigitalCardsTermsText: 'Trykk her for å lese vilkårene for digitale gavekort.',
  DigitalCardsTermsPath: 'download/digital_cards_terms_no.pdf',
  DigitalGiftCardRefillDisabled: 'Påfyll av digitalt gavekort er midlertidig deaktivert.',
  BrunstadCardsTermsText: 'Trykk her for å lese vilkårene for Oslofjords gavekortløsning.',
  BrunstadCardsTermsPath: 'download/payment_cards_terms_no.pdf',
  BrunstadCards: 'Brunstadkort',
  RefillReceipts: 'Vis kvitteringene',
  RefillBrunstadCardTemporarilyDisabled: 'På grunn av utskiftning av nytt kassesystem på Oslofjord, er påfylling av brunstadkort desverre ikke tilgjengelig for øyeblikket.<br /><br />Det jobbes med ny løsning for påfylling online. Denne løsningen kommer i løpet av 2024.',
  RefillSamvirkCardTemporarilyDisabled: 'Påfylling av digitalt gavekort er dessverre ikke tilgjengelig for øyeblikket. En eventuelt gjenværende saldo på dette kortet er fortsatt tilgjengelig for bruk.',
  RefillSamvirkCardTemporarilyDisabledExtended: 'Påfylling av digitalt gavekort er dessverre ikke tilgjengelig for øyeblikket. En eventuelt gjenværende saldo på dette kortet er fortsatt tilgjengelig for bruk. Eventuell saldo på ditt digitale gavekort som skal overføres til å betale din hotellavtale vil bli overført innen eller før 31. juli 2024.',
  Close: 'Lukk',
  Cancel: 'Avbryt',
  ShowCards: 'Kortoversikt',
  Refills: 'Påfyllinger',
  Purchases: 'Forbruk',
  Update: 'Oppdater',
  UpdateBalance: 'Oppdater saldo',
  Refill: 'Fyll på',
  MonthlyRefill: 'Månedlig påfylling',
  Block: 'Sperr',
  Receipt: 'Kvittering',
  ReceiptSubtitle: 'Påfylling av betalingskort',
  PaymentReceiptSubtitle: 'Betaling med betalingskort',
  OrderRef: 'Ordre-id',
  OrgNumber: 'Org nr',
  PaymentMethod: 'Betalingsmetode',
  Total: 'SUM',
  FillCard: 'Fyll på kortet',
  Transfer: 'Overfør',
  Balance: 'Saldo',
  Amount: 'Beløp',
  NewBalance: 'Ny saldo',
  Blocked: 'Sperret',
  Ok: 'OK',
  BlockCardConfirmation: 'Vil du virkelig sperre betalingskortet?',
  BlockCardTitle: 'Sperr kort',
  GoToPayment: 'Betal med debet/kreditt-kort',
  CardTransferBalanceTitle: 'Overfør fra kort til kort',
  ToCard: 'Til kort',
  FromCard: 'Fra kort',
  CardsNotSelectedError: 'Du må velge et kort å overføre til',
  TransferAmountBelowZeroError: 'Du må spesifisere et beløp høyere enn 0',
  CardTransferBalanceGenericError: 'Noe gikk galt',
  PleaseEnterValidAmount: 'Vennligst legg inn et beløp mellom {minAmount} og {maxAmount}.',
  MaxBalanceWarning: 'Ny saldo kan ikke overstige {maxBalance}.',
  PersonalCardReminder: 'Husk at kortet er personlig og skal kun fylles på av deg.',
  PaypalSuccessfulTitle: 'Paypal-betaling',
  PaypalSuccessfulMessage: 'Betalingen er vellykket. Merk at det kan gå inntil et minutt før betalingen er ferdig behandlet og kortsaldoen din er oppdatert.',
  SupportedCards: 'Følgende kort kan brukes',
  PaypalAsAlternative: 'Dersom du ikke kan betale med noen av disse kortene, kan du trykke her for å betale med Paypal.',
  CardNumber: 'Kortnummer',
  DayNOfMonth: '{day}. hver måned',
  InitializeSubscription: 'Aktiver med debet/kreditt-kort',
  LastDayOfMonth: 'Siste dag i måneden',
  NextExecution: 'Neste utførelse',
  PaymentDay: 'Betaling',
  RecurringFillTitle: 'Automatisk månedlig påfylling',
  RemoveRecurringRefill: 'Stopp månedlig påfylling',
  SubscriptionHistory: 'Historikk',
  SubscriptionActivated: 'Automatisk påfyllig aktivert',
  SubscriptionPaymentSuccessful: 'Automatisk påfylling vellykket',
  SubscriptionPaymentFailed: 'Automatisk påfylling feilet',
  SubscriptionDeactivated: 'Automatisk påfylling stoppet',
  SubscriptionChangeNotification: 'Dersom du ønsker å gjøre endringer i beløp, dato eller kort, vennligst stopp den automatiske påfyllingen og aktiver på ny.',
  RefillWarning: 'OBS! Du er i ferd med å fylle på Brunstadkort. Disse pengene kan ikke flyttes til Samvirk digitalt kort om det er fylt på feil. Angreretten gjelder ikke på påfyll av Brunstadkort.',
  LastUpdated2023: 'Sist oppdatert 31.12.2023'
};

export default nb;
