



















































































































import {
  Component, Vue
} from 'vue-property-decorator';
import { BFormInput } from 'bootstrap-vue';
import PayPal from 'vue-paypal-checkout';
import api from '@/services/api';
import http from '@/services/http';

@Component({
  components: {
    PayPal
  }
})
export default class PaymentCardFill extends Vue {
  public amount = '';

  private charCode: any;

  private e: any;

  get currentCard() {
    return this.$cards.currentCard!;
  }

  get newAmount() {
    if (!this.amount) {
      return Math.floor(this.$cards.currentCard!.balance);
    }
    return Math.floor(this.$cards.currentCard!.balance) + parseInt(this.amount, 10);
  }

  samvirkMinAmount = 100;

  samvirkMaxAmount = 100000;

  samvirkMaxBalance = 180000;

  oslofjordMinAmount = 1;

  oslofjordMaxAmount = 10000;

  oslofjordMaxBalance = 10000;

  get isAmountValid() {
    const amount = (this.amount && parseInt(this.amount, 10)) || 0;
    if (this.$cards.currentCard!.type === 0) {
      return amount >= this.oslofjordMinAmount && amount <= this.oslofjordMaxAmount && this.newAmount <= this.oslofjordMaxBalance;
    }
    return amount >= this.samvirkMinAmount && amount <= this.samvirkMaxAmount && this.newAmount <= this.samvirkMaxBalance;
  }

  get formMinAmount() {
    if (this.$cards.currentCard!.type === 0) return this.oslofjordMinAmount;
    return this.samvirkMinAmount;
  }

  get formMaxAmount() {
    if (this.$cards.currentCard!.type === 0) return this.oslofjordMaxAmount;
    return this.samvirkMaxAmount;
  }

  get canPay() {
    return this.isAmountValid && !this.temporarilyDisabled;
  }

  // Returns true if the current card is a brunstad card and the user also has a digital gift card
  get userHasMultipleCardTypes() {
    const cards = this.$cards.getOrderedCards;
    return this.$cards.currentCard!.cardType === 13 && cards!.some((e) => e.cardType === 0);
  }

  /* eslint-disable @typescript-eslint/camelcase */
  private paypal = {
    show: false,
    environment: '',
    client: null as any,
    invoiceNumber: '',
    experienceOptions: {
      input_fields: {
        no_shipping: 1
      }
    },
    buttonStyle: {
      label: 'pay',
      size: 'responsive',
      shape: 'rect',
      color: 'blue',
      tagline: false
    }
  }

  get paypalItems() {
    return [
      {
        name: this.$t('FillCard'),
        description: this.currentCard.cardHolderName,
        quantity: 1,
        price: this.amount,
        currency: 'NOK'
      }
    ];
  }

  isInitializingPayment = false;

  public initializePayment() {
    this.isInitializingPayment = true;

    if (this.currentCard.type === 0) {
      // NEW API
      api.verifone.initializePaymentV2(this.currentCard.mifareId, parseFloat(this.amount)).then((checkoutSession) => {
        window.location.href = checkoutSession.url;
      }).catch(() => { this.isInitializingPayment = false; });
    } else {
      // OLD API
      api.verifone.initializePayment(this.currentCard.mifareId, parseFloat(this.amount)).then((paymentForm) => {
        const form = this.$refs.verifoneForm as HTMLFormElement;
        form.action = paymentForm.verifonePaymentUrl;

        Object.entries(paymentForm.form).forEach(([key, value]) => {
          const input = document.createElement('input');
          input.setAttribute('type', 'hidden');
          input.setAttribute('name', key);
          input.setAttribute('value', value as string);
          form.appendChild(input);
        });
        form.submit();
      }).catch(() => { this.isInitializingPayment = false; });
    }
  }

  public paypalCompleted() {
    this.$bvModal.msgBoxOk(this.$t('PaypalSuccessfulMessage').toString(), {
      title: this.$t('PaypalSuccessfulTitle').toString(),
      okVariant: 'success'
    })
      .then(() => {
        this.$refills.loadForCard(parseInt(this.currentCard.mifareId, 10));
        this.$cards.loadAll();
      })
      .catch((err) => {
        // An error occurred
      })
      .finally(() => {
        this.goBack();
      });
  }

  reloadSamvirkTimer = 0;

  temporarilyDisabled = false;

  public loadSamvirkSavingRule() {
    api.samvirk.getSavingRules().then((savingRule) => {
      this.temporarilyDisabled = savingRule.disabled;
    });
  }

  public mounted() {
    const input = (this.$refs.numberInput as BFormInput);
    input.focus();
    input.select();

    if (this.currentCard.cardType === 0) {
      this.loadSamvirkSavingRule();
      this.reloadSamvirkTimer = window.setInterval(this.loadSamvirkSavingRule, 10000);
    }

    api.paypal.getOptions(this.currentCard.mifareId).then((options) => {
      if (options && options.environment && options.clientId) {
        this.paypal.environment = options.environment;
        this.paypal.client = {
          sandbox: options.environment === 'sandbox' ? options.clientId : null,
          production: options.environment !== 'sandbox' ? options.clientId : null,
        };
        this.paypal.invoiceNumber = options.invoiceNumber;
      }
    });
  }

  public clearSavingsRulePolling() {
    if (this.reloadSamvirkTimer) {
      window.clearInterval(this.reloadSamvirkTimer);
      this.reloadSamvirkTimer = 0;
    }
  }

  goBack() {
    this.clearSavingsRulePolling();
    this.$router.go(-1);
  }
}
