






import { Component, Prop, Vue } from 'vue-property-decorator';
import PaymentCardsList from '@/components/PaymentCardsList.vue';

@Component({ components: { PaymentCardsList } })

export default class MyPaymentCards extends Vue {
  @Prop() private msg!: string;
}
