/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Store, Module, MutationTree, ActionTree, GetterTree
} from 'vuex';

export default class BaseStore<TState> implements Module<TState, any> {
  public namespaced = true;

  // store Mutations
  public mutations: MutationTree<TState> = {};

  // store Actions
  public actions: ActionTree<TState, any> = {};

  // store Getters
  public getters: GetterTree<TState, any> = {};

  /* eslint-disable no-useless-constructor */
  constructor(public name: string, protected rootStore: Store<any>, public state: TState) {
  }

  protected dispatch<T>(action: string, payload?: {}): Promise<T> {
    return this.rootStore.dispatch(`${this.name}/${action}`, payload);
  }

  protected read<T>(name: string, payload?: {}): T {
    const getter: any = this.rootStore.getters[`${this.name}/${name}`];
    if (payload) {
      return getter(payload);
    }
    return getter;
  }

  protected commit(name: string, payload?: {}): void {
    return this.rootStore.commit(`${this.name}/${name}`, payload);
  }
}
