




















































/* eslint-disable class-methods-use-this */
import {
  Component, Vue, Watch
} from 'vue-property-decorator';
import Card from '@/model/card/Card';
import TransferBalanceAction from '../model/card/TransferBalanceAction';

@Component({
  components: {
  }
})
export default class PaymentCardFill extends Vue {
  private amount = '0';

  private showSpinner = false;

  private transferAction: TransferBalanceAction = {
    sourceMifareId: '',
    targetMifareId: '',
    amount: 0
  };

  private sourceCardOptions: Array<any> = [];

  private targetCardOptions: Array<any> = [];

  private availableCards: Card[] = [];

  public mounted() {
    this.showSpinner = false;

    this.sourceCardOptions = this.$cards.state.cards
      .map((c) => ({
        value: c.mifareId,
        text: `${c.cardHolderName} - ${this.getCardType(c)} (${this.format(c.balance)} kr) ${c.isBlocked ? ` - ${this.$t('Blocked').toString()}` : ''}`
      }));

    this.transferAction.sourceMifareId = this.$cards.currentCard!.mifareId;
  }

  @Watch('transferAction.sourceMifareId')
  onSelectedSourceCardChanged(value: string) {
    if (this.transferAction.targetMifareId === value) this.transferAction.targetMifareId = '';

    const sourceCard = this.$cards.state.cards.find((c) => c.mifareId === this.transferAction.sourceMifareId);

    this.targetCardOptions = this.$cards.state.cards.filter((c) => {
      if (sourceCard && c.type !== sourceCard.type) return false;
      return c.mifareId !== this.transferAction.sourceMifareId && !c.isBlocked;
    }).map((c) => ({
      value: c.mifareId,
      text: `${c.cardHolderName} - ${this.getCardType(c)} (${this.format(c.balance)} kr) ${c.isBlocked ? ` - ${this.$t('Blocked').toString()}` : ''}`
    }));

    if (this.targetCardOptions.length === 1) this.transferAction.targetMifareId = this.targetCardOptions[0].value;
  }

  async transfer() {
    if (this.transferAction.targetMifareId === '' || this.transferAction.sourceMifareId === '') {
      alert(this.$t('CardsNotSelectedError').toString());
      return;
    }

    this.transferAction.amount = Number(this.amount.replace(',', '.'));
    if (this.transferAction.amount <= 0) {
      alert(this.$t('TransferAmountBelowZeroError').toString());
      return;
    }

    this.showSpinner = true;
    try {
      await this.$cards.transferBalance(this.transferAction);
      this.goBack();
    } catch {
      alert(this.$t('CardTransferBalanceGenericError').toString());
    }

    this.showSpinner = false;
  }

  goBack() {
    this.$router.go(-1);
  }

  format(number: number) {
    return number.toLocaleString(undefined, { minimumFractionDigits: number % 1 !== 0 ? 2 : 0 });
  }

  getCardType(card: Card) {
    if (card.type === 1) return this.$t('SamvirkCard');
    return (card.cardType === 1 || card.cardType === 14) ? this.$t('armband') : this.$t('card');
  }
}

